video{
    width: 100%;
    height: 100%;
    padding: 7px;
    border-radius: 30px;
    object-fit: cover;
}

.popup{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Move the item back to the center */
    width: 100%;
    height: 100%;
}

.skim{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Move the item back to the center */
  width: 100%;
}

.swal2-title {
    position: relative;
    max-width: 100%;
    margin: 0;
    padding: 0.8em 1em 0;
    color: inherit;
    font-size: 15px !important;
  }

  .scanner-scan {
    height: 100% !important;
    width: 100%;
    border-radius: 20px;
    box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
    display: block;
    object-fit: cover;
    padding: 7px;
    position: absolute !important;
    top: 0;
  }

  .scanner-scan canvas, .scanner-scan svg{
    height: 100%;
  }

  .unloaded .loader{
    text-align: center;
  }

  .unloaded .error-blk{
    text-align: center;
  }

  .claim-start-btn input{
    background-color: #f6e3b7;
    padding: 8px 40px;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    line-height: 22px;
    color: var(--text-color);
    width: 100%;
    border: none;
  }

  .container-fluid {
    background-color: #fff; /* Example background color */
    color: #000;            /* Example text color */
  }

  .show{
    display: block;
  }

  .hide{
    display: none;
  }

  .lang-sec {
    padding-top: 200px !important;
  }

  video {
    pointer-events: none !important;
  }

  video::-webkit-media-controls {
    display: none !important;
  }

  .back-btn img {
    width: 75px !important;
}

.play-list li h3 {
  width: 90%;
}

.prod-con{
  position: absolute;
  width: 45%;
  top : -60px;
  left:0;
  right:0;

  margin-left: auto;
  margin-right: auto;

}
.prod-name{
  background-image: url("../images/Box_Name.png");
  background-size: 100% 100%;
  height: 60px;
  display: flex;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  text-align: center;
}