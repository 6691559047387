*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
ul{
    padding: 0;
    margin: 0;
    list-style: none;
}
img{
    max-width: 100%;
}
a{
    text-decoration: none;
    color: #000;
}
html,body{
    scroll-behavior: smooth;
    /* overflow-x: hidden; */
}
body{
    font-family: 'Poppins';
}
:root{
    --text-color: #14375b;
}
/* --- */
.container {
    max-width: 370px !important;
    padding: 0;
    margin: auto;
    position: relative;
  }
  .claim-prize01-page.container{
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         height: 86vh;
         align-items: center;
  }
  .instruction-page.container {
    max-width: 370px !important;
    padding: 0;
    margin: auto;
    position: relative;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
    /* height: 76vh; */
    gap: 105px;
}
  .common-bg {
    width: 100%;
    min-height: 100vh;
    background-image: url('../images/Bg_Base@4x.png');
    background-repeat: no-repeat;
    max-width: 430px;
    margin: auto;
    position: relative;
    overflow: hidden;    
  }
  /* --top-img-- */
  .top-img::after{
    position: absolute;
    content: '';
    background-image: url(../images/Bg_TopGold@4x.png);
    top: 0;
    height: 13vh;
    background-repeat: no-repeat;
    background-size: 104% 114%;
    width: 100%;
  }
  .top-img,.bottom-img{
    position: relative;
  }
  .bottom-img::after{
    position: absolute;
    content: '';
    background-image: url(../images/Bg_BotGold@4x.png);
    left: 0;
    height: 79vh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    right: 0;
    top: 268px;
  } 

  /* --tnc popup */
  .modal-dialog {
    max-width: 390px;
  }
  .modal-content{
        /* background-image: url('../images/HowToPlay_Bg@4x.png'); */
        background-color: #fff2c9;
        border: 5px solid #e5c471;
        width: 100%;
        padding: 10px 30px;
        border-radius: 10px;
  }
  /* .modal-body {
    padding: 0;
  } */
  .modal-body h3{
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
  }
  .modal-body{
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
  }
  /* ---home page--- */
  .lang-sec{
    padding-top: 260px;
  }
.launguage-div{
  text-align: center;
}
.launguage-div h3{
    font-size: 34px;
    line-height: 36px;
    font-weight: 400;
    color: var(--text-color);
    margin-bottom: 20px;
}
.launguage-div .btn{
  width: 100%;
  padding: 12px 20px;
  margin-bottom: 10px;
  border: none;
border-radius: 20px;
width: 100%;
max-width: 300px;
margin-top: 10px;
font-size: 20px;
line-height: 22px;
box-shadow: 2px 2px 4px 4px rgba(0, 0, 0, 0.3);
cursor: pointer;
}
.launguage-div .btn-eng{
  background-color: var(--text-color);
  color: white;
}
.launguage-div .btn-china{
color: var(--text-color);
background-color: white;
}
  /* ---instruction-- */
 
  .logo{
    padding: 10px;
    position: relative;
  }
  .logo img{
width: 100%;
max-width: 80px;
  }
  .home-text{
    padding-top: 45px;
    text-align: center;
    margin-bottom: 0;
  }
  .home-text img{
    width: 100%;
    max-width: 250px;
  }
  .hero-sec{
    padding-top: 42px;
  }
  .play-sec{
    background-image: url('../images/HowToPlay_Bg@4x.png');
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 15px;
  }
  .hero-top-btn{
    margin-top: -29px;
    margin-bottom: 20px;
    text-align: center;
  }
  .play-btn{
    background-color: #ffd97e;
    text-transform: uppercase;
    color: var(--text-color);
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    padding: 5px 20px;
    border-radius: 20px;
    margin: 0 auto;
    border: 0;
    box-shadow: inset 0 10px 15px rgba(255, 255, 255, 0.3),
      inset 0 -10px 15px rgba(255, 255, 255, 0.3), 5px 4px 5px 0px rgba(0, 0, 0, 0.5);
  }

  .play-list li {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 10px;
  }
  .play-list li .count{
    color: var(--text-color);
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }
 
 .cutm-icon img{
    width: 100%;
    max-width: 82px;
 }
  .play-list li h3 span{
    color: var(--text-color);
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
  }
  .play-list li h3 {
    color: var(--text-color);
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }

.hero-checkbox {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  /* margin-bottom: 20px; */
}
  /* ----check box */
 /* .hero-checkbox .form-check-input:checked,
.hero-checkbox .form-check-input:focus {
  box-shadow: none;
  outline: none;
} */
 .hero-checkbox .form-check-input {
    width: 18px;
    height: 18px;
    cursor: pointer;
    border: none;
    outline: none;
  }
 /* .hero-checkbox .form-check-input:checked {
    background-color: white;
  } */
  .hero-checkbox .form-check-label{
    font-size: 12px;
    line-height: 12px;
    font-weight: 400;
  }
  .hero-checkbox .form-check{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 6px;
    color: var(--text-color);
  }
  .start-btn{
    text-align: center;
    margin-bottom: 20px;
    padding: 40px 0 0;
    position: relative;
  }
  .instruction-btn.start-btn{
    text-align: center;
    margin-bottom: 20px;
    padding: 28px 0 0;
    position: relative;

  }
.start-btn a{
     color: var(--text-color);
     text-transform: uppercase;
     font-size: 20px;
     line-height: 22px;
     font-weight: 600;
     padding: 8px 40px;
     background-color: white;
     box-shadow: 3px 4px 6px 2px rgba(0, 0, 0, 0.3);
     border-radius: 10px;
}
.instruction-btn a{
  padding: 8px 72px;
}
.claim-start-btn{
  text-align: center;
}
.claim-start-btn button{
  background-color: #f6e3b7;
  padding: 8px 40px;
  font-size: 20px;
  font-weight: 400;
  line-height: 22px;
  color: var(--text-color);
  width: 100%;
  border: none;
}
/* ----huntlist-sec--- */

.huntlist-sec{
    padding-top: 110px;
}
.huntlist-sec .title{
color: white;
font-size: 44px;
line-height: 49px;
font-weight: 600;
margin: 0 auto;
text-align: center;
}
.huntlist-01-title{
  margin-top: 120px;
}
.huntlist-01-title .title{
  color: white;
  font-size: 28px;
  line-height: 40px;
  font-weight: 400;
margin: 0 auto;
text-align: center;
}
.huntlist-content{
    margin-top: 177px;
    text-align: center;
    margin-bottom: 20px;
    position: relative;
}
.huntlist-content .btn{
    background-color: var(--text-color);
    padding: 7px 20px;
    border-radius: 20px;
    color: white;
    border: none;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0 auto;
}
.huntlist-content .bottal-fill{
  width: 13px;
}
.bottal-fill.active path{
  fill: #ffd97e;
}
.slider{
    position: relative;
}
.owl-nav .prev-left{
  position: absolute;
  top: 66%;
  left: 10px;
  width: 26px;
  z-index: 1;
  cursor: pointer;
}
.owl-nav .prev-right{
  position: absolute;
  top: 66%;
  right: 10px;
  width: 26px;
  z-index: 1;
  cursor: pointer;
}
.slider-btm-title{
  margin-bottom: 30px;
  margin-top: 10px;
  padding-bottom: 30px;
}
.slider-btm-title h3{
  font-size: 28px;
  line-height: 30px;
  font-weight: 700;
  color: var(--text-color);
  text-align: center;
  position: relative;
  z-index: 1;
  margin: 0 auto;
}
.chines.slider-btm-title h3{
  font-weight: 500;
} 


/* ----scaneAr-sec---- */
.scaneAr-sec{
  padding-top: 41px;
  padding-bottom: 20px;
}
.scaneAr-imgdiv{
  position: relative;
  /* margin-bottom: 20px; */
  background-image: url('../images/Rectangle\ 3919.png');
  background-size: cover;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 505px;
  z-index: 2;
}
.arActivation-scaneAr-imgdiv{
  position: relative;
    margin-bottom: 20px;
  /* background-image: url('../images/2_7mb.gif');
  background-size: cover;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat; */
  width: 100%;
  height: 465px;
  z-index: 2;
}
.arActivation-scaneAr-imgdiv::after{
  background-image: url('../images/2_7mb.gif');
  background-size: cover;
  content: "";
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 465px;
  top: 0;
  left: 0;
  z-index: 3;
}
.scanner-scan{
  border-radius: 20px;
  box-shadow: inset 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  /* margin-bottom: 20px; */
  display: block;
  object-fit: cover;
  z-index: 3;
  position: relative;
  width: 100%;
  height: 505px !important;
}
.arActivation-scaneAr-imgdiv .scanner-scan{
  height: 465px !important;
}
.scaneAr-imgdiv .top-left-img ,.arActivation-scaneAr-imgdiv .top-left-img{ 
     position: absolute;
     top: -50px;
     right: -10px;
     width: 80px;
     z-index: 4;
}
.scaneAr-text h3{
  color: var(--text-color);
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
}
.chines.scaneAr-text h3{
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
}
.qr-scanner{
  position: absolute;
  top: 46%;
  left: 41.5%;
  width: 70px;
  height: 70px;
}
.back-btn{
  display: flex;
  align-items: center;
  color: white;
  gap: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
}
.back-btn img{
  width: 17px;
}
.claim-prize h3{
  color: white ;
  width: 100%;
  font-size: 42px;
  line-height: 48px;
  text-align: center;
  margin: 82px auto 0;
  margin-bottom: 10px
}
.claim-prize h5{
  color: white;
  text-align: center;
  font-weight: 400;
  font-size: 23px;
  line-height: 25px;
}

/* ----claim your price */
.claim-price-content h4{
  font-size: 21px;
  line-height: 28px;
  font-weight: 700;
  width: 100%;
  color: var(--text-color);
  text-align: center;
  margin-bottom: 14px;
}
.claim-01-text h4{
  font-weight: 500 ;
}
.chines.claim-01-text h4{
  font-weight: 600;
  font-size: 19px;
  line-height: 20px;
}

/* ---end page--- */
.end-content{
  text-align: center;
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.end-content h3{
  font-size: 34px;
  line-height: 45px;
  font-weight: 500;
  color: var(--text-color);
}


@media (max-width:575px){
   .start-btn {
    padding-top: 20px;
  }

}

@media (max-width:399px){
  .container {
    max-width: 353px !important;
  }
  .owl-nav .prev-left ,.owl-nav .prev-right {
    top: 67%;
}
}
@media (max-width:375px){

  .huntlist-sec .title {
    font-size: 36px;
    line-height: 42px;
  }
  .launguage-div h3{
    font-size: 26px;
    line-height: 26px;
  }
  .container{
    max-width: 300px !important;
  } 


  .bottom-img.custm-index img {
    bottom: 0;
  }
  
  
  .end-content {
    height: 50vh;
    margin-top: 56px;
  }
  .home-text {
    padding-top: 21px;
  }
  .huntlist-01-title .title {
    font-size: 23px;
    line-height: 34px;
  }
  .scaneAr-imgdiv {
    height: 475px;
  }
  .scanner-scan{
    height: 475px !important;
  }
}

@media (max-width:320px){
  .launguage-div h3 {
    font-size: 22px;
        line-height: 24px;
  }
 
  .huntlist-01-title .title {
    font-size: 20px;
    line-height: 34px;
  }
  .owl-nav .prev-right,.owl-nav .prev-left {
    top: 67%;
    width: 20px;
}
.end-content h3 {
  font-size: 25px;
  line-height: 31px;
  font-weight: 500; 
}
.logo img {
  max-width: 64px;
}
.home-text img{
  max-width: 225px;
}
.container{
  max-width: 277px !important;
}
.scaneAr-text h3 {
  font-size: 13px;
}
.slider-btm-title h3 {
  font-size: 24px;
  line-height: 26px;
}

}

